<template>
    <div class="custom-slider">
        <div class="content-wrapper" :style="{'transform': `translateX(-${xPercent / (device === 'mobile' ? 1.5 : 1.7)}%)`}">
            <slot name="content"></slot>
        </div>
        <el-slider v-model="xPercent" :show-tooltip="false" :step="0.1"></el-slider>
    </div>
</template>

<script>
export default {
    name: "custom-slider",
    props: ['device'],
    data(){
        return {
            xPercent: 0
        }
    }
}
</script>

<style lang="less">
div.custom-slider{
    justify-content: center;
    flex-direction: column;
    // align-items: center;
    display: flex;
    width: 100%;
    .content-wrapper{
        width: max-content;
        display: flex;
    }
    .el-slider{
        margin: auto;
    }
    @media screen and (min-device-width: 1200px) {
        .el-slider{
            margin: 5.6rem auto;
        }
        .el-slider__runway, .el-slider__bar{
            background: rgba(0, 0, 0, .2);
            height: 0.1rem;
            width: 9.6rem;
        }
        .el-slider__button-wrapper{
            top: -1.7rem;
        }
        .el-slider__button{
            border: .1rem solid #000000;
            border-radius: 11.7rem;
            height: 0.9rem;
            width: 1.8rem;
        }
    }
    @media screen and (max-device-width: 1199px) {
        padding-left: 1.0667vw;
        .el-slider__runway{
            margin: 6.4vw 0 0 0;
        }
        .el-slider__runway, .el-slider__bar{
            background: rgba(0, 0, 0, .2);
            height: 0.2667vw;
            width: 26.1333vw;
        }
        .el-slider__button-wrapper{
            top: -1.7rem;
        }
        .el-slider__button{
            border: .1rem solid #000000;
            border-radius: 1.4vw;
            height: 2.4vw;
            width: 4.8vw;
        }
    }
}
</style>