<template>
    <div class="home main-wrapper">
        <!-- 轮播图 -->
        <el-carousel
            indicator-position="outside"
            :key="carouselHeight"
            :autoplay="false"
            id="carousel"
            :trigger="'hover'"
            :ref="'carousel'"
            :height="carouselHeight">
            <div @touchstart="startSwipe($event)" @touchmove="endSwipe($event, 'carousel')" v-for="(carousel, index) in carouselList" :key="'carousel' + index" @click="handleCarouselClick(carousel)">
                <el-carousel-item>
                    <img :src="carousel.imgUrl" />
                    
                    <!-- 标题描述 -->
                    <div v-if="carousel.title.length > 0" class="carousel-wrapper" :class="[index % 2 === 0 ? 'left' : 'right']">
                        <p v-for="title in $store.getters.getLanguage === '-EN' ? carousel.enTitle : carousel.title" :key="title" :class="[carousel.hintType]">{{ title }}</p>
                        <div class="more-detail" v-if="carousel.title.length > 0">
                            <span :class="[carousel.hintType]">
                                {{ $store.getters.getLanguage === '-EN' ? 'Learn More' : '查看更多'}}
                            </span>
                            <div class="hint" :class="[carousel.hintType]">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </div>
        </el-carousel>
        <!-- 品牌主张 -->
        <div class="img-wrapper">
            <img :src="imageMap.img_1" :key="imageMap.img_1"/>
            <div class="wrapper">
                <p v-for="(content, index) in imageContent.img_1[$store.getters.getDevice][$store.getters.getLanguage === '' ? 'CN' : 'EN']" 
                    :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']"
                    :key="'img-content' + index">
                    {{ content }}
                </p>
            </div>
        </div>
        <!-- 动图 -->
        <div class="img-wrapper gif">
            <img :src="imageMap.gif" :key="imageMap.gif"/>
            <div class="gif-wrapper">
                <span v-if="$store.getters.getLanguage === '-EN'">SENCL adheres to the concept of environmental protection, and pursues
                sustainable development by manufacturing pure natural raw materials    </span>
                <span v-else>华睿生物坚守环保理念，<br v-if="$store.getters.getDevice === 'mobile'"/>制造纯天然原料追求可持续发展</span>
            </div>
        </div>
        <div class="img-wrapper multiple-media">
            <div class="text-panel">
                <div class="text-wrapper">
                    <div class="hint-title">
                        <p>{{ $store.getters.getLanguage === '' ? '华睿生物' : 'SENCL' }}</p>
                        <img class="hint-bar" src="../assets/images/signal-bar.webp">
                    </div>
                    <div class="description" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
                        {{ $store.getters.getLanguage === '' ? 
                            '华睿生物成立于2012年，总部位于湖州南太湖医药产业园，是一家以绿色合成生物技术为创新驱动，拥有先进的酶催化、代谢工程和生物发酵技术，并集研发、生产、销售为一体的国际化高科技企业。' : 
                            'Founded in 2012, SENCL is headquartered in Huzhou South Taihu Pharmaceutical Industrial Park. It is an international high-tech company driven by synthetic biology technology, with advanced enzyme catalysis, metabolic engineering and bio-fermentation technology, integrating R&D, production and sales. ' }}
                    </div>
                    <div class="description" :class="[$store.getters.getLanguage === '' ? '' : 'EN']">
                        {{ $store.getters.getLanguage === '' ? 
                            '公司主要产品为运动营养、脑部健康、抗衰长寿、美白保湿、医药中间体及功能性添加剂等相关产品，并成为β-丙氨酸、BHB、麦角硫因、甘油二酯等多个领域的龙头企业。' : 
                            `The company's main products are a series of fine chemicals for sports nutrition，brain health, anti-aging and longevity, whitening and moisturizing, pharmaceutical intermediates and functional additives. SENCL has become a leading enterprise in many industries such as β-alanine, BHB, and ergothioneine.` }}
                        
                    </div>
                </div>
            </div>
            <img class="img-bg" :src="imageMap.img_2">
        </div>
        <!-- 饼图 -->
        <div class="circle" :style="{'background-image': `url(${imageMap.img_3})`}" :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']">
            <div class="circle-title">
                <span v-if="$store.getters.getLanguage === '-EN'">SENCL Value - <br v-if="$store.getters.getDevice === 'mobile'">Innovative Solution with Integrity</span>
                <span v-else>华睿价值观 - 严肃科技</span>
            </div>
            <div class="circle-content">
                <span v-if="$store.getters.getLanguage === '-EN'">SENCL leads the forefront with serious technology, allowing everyone
                to enjoy beautiful and healthy lives with peace of mind</span>
                <span v-else>华睿生物以严肃科技创领前沿趋势，让每个人安心享有美丽健康</span>
            </div>
            <div class="pie-wrapper" id="pie">
                <img :class="['normal-' + (index + 1)]" v-for="(normalImg, index) in pieList" :key="normalImg.imgKey" :src="normalImg.imgUrl">
                <img :class="['cover-' + (index + 1), 'cover']" v-for="(coverImg, index) in pieList" :key="coverImg.hoverKey" :src="coverImg.hoverUrl">
            </div>
            <div class="title-wrapper" :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']">
                <p class="title">{{ $store.getters.getLanguage === '' ? '严肃科技' : 'Innovative Solution with Integrity'}}</p>
                <p class="content">{{ $store.getters.getLanguage === '' ? '文献支持' : 'Literature support'}}</p>
                <p class="content">{{ $store.getters.getLanguage === '' ? '科学论证' : 'Scientific certification'}}</p>
                <p class="content">{{ $store.getters.getLanguage === '' ? '实验证明' : 'Experimental proof'}}</p>
                <p class="content">{{ $store.getters.getLanguage === '' ? '专利布局' : 'Patent layout'}}</p>
                <p class="content">{{ $store.getters.getLanguage === '' ? '全链路' : 'Full Chain'}}</p>
            </div>
        </div>
        <div :class="['global', $store.getters.getLanguage === '' ? 'CN' : 'EN']" 
             :style="{'background-image': $store.getters.getDevice === 'web' ? `url(${imageMap.global})` : ''}">
            <p class="global-title">{{ $store.getters.getLanguage === '' ? '深化全球业务合作' : 'Deepen global business cooperation'}}</p>
            <div v-if="$store.getters.getDevice === 'mobile'">
                <text-content v-for="item in global" :key="item.title" 
                    :title="item['title' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :content="item['content' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :direction="'column'">
                </text-content>
            </div>
            <div class="global-wrapper" v-else>
                <text-content
                    :title="global[0]['title' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :content="global[0]['content' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :direction="'column'">
                </text-content>
                <div class="global-seperator"></div>
                <text-content
                    :title="global[1]['title' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :content="global[1]['content' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :direction="'column'">
                </text-content>
                <div class="global-seperator"></div>
                <text-content 
                    :title="global[2]['title' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :content="global[2]['content' + ($store.getters.getLanguage === '' ? '' : '_EN')]" 
                    :direction="'column'">
                </text-content>
            </div>
        </div>
        <div class="cert" :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']">
            <p class="cert-title">{{ $store.getters.getLanguage === '' ? '多项行业资质认证' : 'A number of industry qualification certifications'}}</p>
            <!-- 轮播图 -->
            <el-carousel
                indicator-position="outside"
                :key="carouselHeight"
                :autoplay="false"
                id="cert"
                :trigger="'hover'"
                :ref="'cert'"
                :height="certHeight"
                v-if="$store.getters.getDevice === 'mobile'">
                <div @touchstart="startSwipe($event)" @touchmove="endSwipe($event, 'cert')">
                    <el-carousel-item>
                        <div class="img-panel">
                            <img :src="certList[0].imgUrl">
                        </div>
                        <div class="img-panel">
                            <img :src="certList[2].imgUrl">
                        </div>
                        <div class="img-panel">
                            <img :src="certList[1].imgUrl">
                        </div>
                        <div class="img-panel">
                            <img :src="certList[3].imgUrl">
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="img-panel">
                            <img :src="certList[4].imgUrl">
                        </div>
                        <div class="img-panel">
                            <img :src="certList[6].imgUrl">
                        </div>
                        <div class="img-panel">
                            <img :src="certList[5].imgUrl">
                        </div>
                        <div class="img-panel">
                            <img :src="certList[7].imgUrl">
                        </div>
                    </el-carousel-item>
                    <el-carousel-item>
                        <div class="img-panel">
                            <img :src="certList[8].imgUrl">
                        </div>
                    </el-carousel-item>
                </div>
            </el-carousel>
            <div class="cert-list" v-else>
                <div class="img-panel" v-for="item in certList" :key="item.imgKey">
                    <img :src="item.imgUrl">
                </div>
            </div>
        </div>
        <div class="future" :style="{'background-image': `url(${imageMap.future})`}" :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']">
            <p class="title">{{ $store.getters.getLanguage === '' ? '我们的未来' : 'Our future'}}</p>
            <custom-slider v-if="$store.getters.getDevice === 'mobile'" :device="$store.getters.getDevice">
                <template slot="content">
                    <div v-for="item in cardList" :key="item.title_1" class="card" :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']">
                        <p class="title-1">{{ item['title_1' + ($store.getters.getLanguage === '' ? '' : '_EN')] }}</p>
                        <img class="hint-bar" src="../assets/images/signal-bar.webp">
                        <p class="title-2">{{ item['title_2' + ($store.getters.getLanguage === '' ? '' : '_EN')] }}</p>
                        <p class="content">{{ item['content' + ($store.getters.getLanguage === '' ? '' : '_EN')] }}</p>
                    </div>
                    <!-- <div :style="{'height': '100px', 'width': '140vw', 'background': 'red'}"></div> -->
                </template>
            </custom-slider>
            <div class="future-wrapper" v-if="$store.getters.getDevice === 'web'">
                <div v-for="item in cardList" :key="item.title_1" class="card" :class="[$store.getters.getLanguage === '' ? 'CN' : 'EN']">
                    <p class="title-1">{{ item['title_1' + ($store.getters.getLanguage === '' ? '' : '_EN')] }}</p>
                    <img class="hint-bar" src="../assets/images/signal-bar.webp">
                    <p class="title-2">{{ item['title_2' + ($store.getters.getLanguage === '' ? '' : '_EN')]}}</p>
                    <p class="content">{{ item['content' + ($store.getters.getLanguage === '' ? '' : '_EN')]}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCosUrl, handleSwipe } from "../utils";
import CustomSlider from '../components/customSlider.vue';
import TextContent from '../components/textContent.vue';
export default {
  name: "home",
  components: { CustomSlider, TextContent },
  data() {
    return {
        imageMap: {
            img_1: "",
            gif: "",
            img_2: "",
            img_3: "",
            global: "",
            future: ""
        },
        imageContent: {
            img_1: {
                web: {
                    CN: ['华睿生物的品牌主张是', '合成万物精华   滋养生命魅力', 'Synthesize Essence, Nourish the Charm of Life'],
                    EN: ['Sencl brand proposition', 'Synthesize Essence, Nourish the Charm of Life']
                },
                mobile: {
                    CN: ['华睿生物的品牌主张是', '合成万物精华   滋养生命魅力', 'Synthesize Essence, Nourish the Charm of Life'],
                    EN: ['Sencl brand proposition', 'Synthesize Essence, Nourish the Charm of Life']
                }
            }
        },
        carouselHeight: "",
        certHeight: "",
        carouselList: [
            {
                imgUrl: "",
                imgKey: "/home/banner-1.webp",
                link: "ProductionService",
                type: 'outside',
                param: null,
                title: ['全球健康美丽生物技术', '全产业链引领者'],
                enTitle: ['Worldwide Health and Beauty Biotechnology', 'Trailblazer of the Complete Industry Ecosystem'],
                hintType: 'reverse'
            },
            {
                imgUrl: "",
                imgKey: "/home/banner-2.webp",
                link: "pie",
                type: 'inside',
                param: null,
                title: ['华睿价值观 - 严肃科技'],
                enTitle: ['SENCL Value - Innovative Solution with Integrity'],
                hintType: 'reverse'
            },
            {
                imgUrl: "",
                imgKey: "/home/banner-3.webp",
                link: "About",
                type: 'outside',
                param: null,
                title: [],
                enTitle: [],
                hintType: ''
            },
            {
                imgUrl: "",
                imgKey: "/home/banner-4.webp",
                link: "Technology",
                type: 'outside',
                param: null,
                title: [],
                enTitle: [],
                hintType: ''
            },
            {
                imgUrl: "",
                imgKey: "/home/banner-5.webp",
                link: "KeyIngredients",
                type: 'outside',
                param: "card1",
                title: ['神奇的麦角硫因'],
                enTitle: ['Amazing EGT'],
                hintType: 'light'
            },
        ],
        pieList:  [
            { imgKey: "/home/pie/pie-1.webp", imgUrl: "", hoverKey: "/home/pie/pie-1-hover.webp", hoverUrl: "" },
            { imgKey: "/home/pie/pie-2.webp", imgUrl: "", hoverKey: "/home/pie/pie-2-hover.webp", hoverUrl: "" },
            { imgKey: "/home/pie/pie-3.webp", imgUrl: "", hoverKey: "/home/pie/pie-3-hover.webp", hoverUrl: "" },
            { imgKey: "/home/pie/pie-4.webp", imgUrl: "", hoverKey: "/home/pie/pie-4-hover.webp", hoverUrl: "" },
            { imgKey: "/home/pie/pie-5.webp", imgUrl: "", hoverKey: "/home/pie/pie-5-hover.webp", hoverUrl: "" },
            { imgKey: "/home/pie/pie-6.webp", imgUrl: "", hoverKey: "/home/pie/pie-6-hover.webp", hoverUrl: "" },
            { imgKey: "/home/pie/pie-7.webp", imgUrl: "", hoverKey: "/home/pie/pie-7-hover.webp", hoverUrl: "" }
        ],
        cardList: [
            {
                title_1: "卓越",
                title_1_EN: "Excellence",
                title_2: "全球竞争力",
                title_2_EN: "Global competitiveness",
                content: "实现从“细胞工厂”到“衣食住行”，不断满足人民群众对美好生活的需要，成为一家具有全球竞争力的伟大企业",
                content_EN: `Realizing the transition from a "cell factory" to a"food, clothing, housing, and transportation",continuously fulfilling people's needs for a better life,and becoming a great enterprise with global competitiveness`
            },
            {
                title_1: "赋能",
                title_1_EN: "Empowerment",
                title_2: "赋能行业发展",
                title_2_EN: "Empowering industry development",
                content: "以强大的合成生物技术创新能力和规模产业化基础，进一步加强制剂研发能力、组合物功效评价、临床法规完善，为健康行业赋能",
                content_EN: `With strong innovation capabilities in synthetic biology and a foundatioon for large-scale industrialization,we will further strengthen our research and development capabilitiesin formulations, evaluate the efficacy of compositions, and improve clinical regulations to empovver the health industry`
            },
            {
                title_1: "创新",
                title_1_EN: "Benefit",
                title_2: "产业升级、绿色制造",
                title_2_EN: "Industrial upgrading and green manufacturing",
                content: "通过研发创新，实现产品高效、低成本、绿色化的大规模商业化制造，充分发挥合成生物技术的颠覆性作用",
                content_EN: `Through R&D innovation, we aim to achieve efficient, low-ccst, and green large-scale commercial manufacturing of products, fully leveraging the disruptive role ofsynthetic biology`
            },
        ],
        swipePosition: {
            startX: 0,
            endX: 0,
            timeStamp: null
        },
        global: [
            {
                title: "美洲市场",
                title_EN: "US market",
                content: "以膳食补充剂原料为核心持续扩大明星产品数量深度布局多层级市场实现产品量价齐升。",
                content_EN: `Taking dietary supplement raw materials as the core, we will continue to expand the number of celebrity products, deeply layout multi-level markets, and achieve a simultaneous increase in product volume and price.`
            },
            {
                title: "欧洲市场",
                title_EN: "European market",
                content: "与欧洲排名前列的成熟贸易商深度绑定；与终端品牌形成定制开发合作模式。",
                content_EN: `For the supply of sustainable cosmetics and skincare products, niche differentiated raw material supply and acquisition of skincare terminal products.`
            },
            {
                title: "亚洲市场",
                title_EN: "Asian market",
                content: "多品类发展产销多点布局提升华睿在日本、东南亚等市场的影响力。",
                content_EN: `Multi category development, multi-point production and sales layout, enhancing SENCL's influence in markets such as Japan and Southeast Asia.`
            },
        ],
        certList: [
            { imgKey: "/home/cert1.webp", imgUrl: "" },
            { imgKey: "/home/cert2.webp", imgUrl: "" },
            { imgKey: "/home/cert3.webp", imgUrl: "" },
            { imgKey: "/home/cert4.webp", imgUrl: "" },
            { imgKey: "/home/cert5.webp", imgUrl: "" },
            { imgKey: "/home/cert6.webp", imgUrl: "" },
            { imgKey: "/home/cert7.webp", imgUrl: "" },
            { imgKey: "/home/cert8.webp", imgUrl: "" },
            { imgKey: "/home/cert9.webp", imgUrl: "" },
        ]
    };
  },
  watch: {
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initCarousels();
                this.initPieImages();
                this.initCertImages();
                this.imageMap.gif = getCosUrl("/home/animate.webp");
                this.imageMap.img_1 = getCosUrl("/home/slogan-bg.webp");
                this.imageMap.img_2 = getCosUrl("/home/global-view.webp");
                this.imageMap.img_3 = getCosUrl("/home/values-bg.webp");
                this.imageMap.future = getCosUrl("/home/future-bg.webp");
                if(newVal === 'web')
                    this.imageMap.global = getCosUrl("/home/global-bg.webp");
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                // this.initCarousels();
                this.initPieImages();
                this.carouselList[2].imgUrl = getCosUrl(this.carouselList[2].imgKey);
                this.carouselList[3].imgUrl = getCosUrl(this.carouselList[3].imgKey);
            }
        },
        deep: true
    }
  },
  mounted() {
    this.initCarousels();
    this.initPieImages();
    this.initCertImages();
    this.imageMap.gif = getCosUrl("/home/animate.webp");
    this.imageMap.img_1 = getCosUrl("/home/slogan-bg.webp");
    this.imageMap.img_2 = getCosUrl("/home/global-view.webp");
    this.imageMap.img_3 = getCosUrl("/home/values-bg.webp");
    this.imageMap.future = getCosUrl("/home/future-bg.webp");
    if(this.$store.getters.getDevice === 'web')
        this.imageMap.global = getCosUrl("/home/global-bg.webp");

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize, true);
    handleSwipe('carousel', this.$refs.carousel);
  },
  destroyed(){
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    initCarousels(){
        const imgList = getCosUrl(this.carouselList.map(item => { return item.imgKey }));
        this.carouselList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    initPieImages(){
        const normalList = getCosUrl(this.pieList.map(item => { return item.imgKey }));
        const hoverList = getCosUrl(this.pieList.map(item => { return item.hoverKey }));
        this.pieList.forEach((element, index) => {
            element.imgUrl = normalList[index];
            element.hoverUrl = hoverList[index];
        })
    },
    initCertImages(){
        const imgList = getCosUrl(this.certList.map(item => { return item.imgKey }));
        this.certList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
    handleWindowResize() {
        const width = document.getElementsByClassName("home")[0].clientWidth;
        if(this.$store.getters.getDevice === 'web')
            this.carouselHeight = 750 * ((width - 15) / 1920) + "px";
        else{
            this.carouselHeight = 468 * (width / 375) + "px";
            this.certHeight = 515 * (width / 375) + "px";
        }
    },
    handleCarouselClick(carousel){
        if(carousel.type === 'inside'){
            const dom = document.querySelector('#' + carousel.link);
            dom.scrollIntoView({behavior: 'smooth'});
        } else if(carousel.type === 'outside'){
            this.$router.push({
                name: carousel.link,
                params: {
                    archor: carousel.param
                }
            })
        }
    },
    startSwipe(e){
        this.swipePosition.startX = e.touches[0].clientX;
    },
    endSwipe(e, ref){
        this.swipePosition.endX = e.touches[0].clientX;
        const now = new Date().getTime();
        if(!this.swipePosition.timeStamp || now > this.swipePosition.timeStamp){
            if (this.swipePosition.startX - this.swipePosition.endX <= 0) {
                this.$refs[ref].prev();
                this.swipePosition.timeStamp = new Date().getTime() + 500;
                return false;
            } else {
                this.$refs[ref].next();
                this.swipePosition.timeStamp = new Date().getTime() + 500;
                return false;
            }
        }
    }
  },
};
</script>

<style lang="less">
div.home{
    flex-direction: column;
    display: flex;
    div.circle-title,
    div.circle-content {
        text-align: center; 
        color: #fff;
    }
    div.img-wrapper{
        align-items: center;
        position: relative;
        display: flex;
        .gif-wrapper{
            text-shadow: 0 0 .6rem #000;
            position: absolute;
            color: #fff;
        }
        @media screen and (min-device-width: 1200px) {
            .gif-wrapper{
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 3.6rem;
                height: 3.6rem;
                width: 80vw;
                left: 50%;
                top: 50%;
            }
            &.multiple-media{
                height: 72rem;
                .text-panel{    
                    justify-content: flex-end;
                    display: flex;
                    width: 50%;
                    .text-wrapper{
                        margin-right: 19.4rem;
                        width: 40.6rem;
                    }
                }
                .img-bg{
                    height: 100%;
                    width: auto;
                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            &.gif{
                height: 48.8vw;
            }
            .gif-wrapper{
                transform: translateY(-50%);
                min-width: 59.7333vw;
                font-size: 4.2667vw;
                left: 9.6vw;
                top: 50%;
            }
            &.multiple-media{
                justify-content: center;
                flex-direction: column;
                padding: 10.6667vw 0;
                align-items: center;
                display: flex;
                .text-panel{    
                    justify-content: center;
                    display: flex;
                    width: 91.2vw;
                    .text-wrapper{
                        width: 80.8vw;
                    }
                }
                .description{
                    text-align: left;
                }
                .img-bg{
                    margin-top: 5.3333vw;
                    width: 91.2vw;
                }
            }
        }
    }
    .wrapper {
        transform: translateX(-50%);
        position: absolute;
        left: 50%;
        p{
            text-align: center;
            color: #021F32;
        }
        @media screen and (min-device-width: 1200px) {
            top: 9.6rem;
            p:nth-child(1){
                font-size: 2.4rem;
            }
            p:nth-child(2){
                margin-top: 1.4rem;
                font-size: 3.6rem;
            }
            p:nth-child(3){
                white-space: break-spaces;
                font-size: 2.4rem;
            }
        }
        @media screen and (max-device-width: 1199px) {
            top: 12.2667vw;
            width: 91.47vw;
            p:nth-child(1){
                font-size: 3.7333vw;
                &:not(.CN){
                    font-size: 3.2vw;
                }
            }
            p:nth-child(2){
                white-space: break-spaces;
                margin-top: 3.2vw;
                font-size: 6.4vw;
                &:not(.CN){
                    margin-top: 2.6667vw;
                    font-size: 3.7333vw;
                }
            }
            p:nth-child(3){
                font-size: 2.6667vw;
                margin-top: 1.6vw;
            }
        }
    }
    .global{
        .global-title{
            text-align: center;
        }
        @media screen and (min-device-width: 1200px) {
            background-repeat: no-repeat;
            background-size: cover;
            height: 72rem;
            .global-title{
                margin: 12.4rem 0 14.5rem 0;
                font-size: 3.6rem;
            }
            .global-wrapper{
                justify-content: space-between;
                width: 104.5rem;
                margin: 0 auto;
                display: flex;
            }
            .global-seperator{
                background: #000;
                height: 13.6rem;
                width: .1rem;
            }
            &.EN{
                // .global-wrapper{
                //     align-items: center;
                // }
                .global-seperator{
                    transform: translateY(6rem);
                }
                div.text-content{
                    p.content{
                        font-size: 2rem;
                        text-align: left;
                    }
                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            background: #fff;
            height: 130.9333vw;
            p.global-title{
                margin-top: 13.3333vw;
            }
            &.CN{
                p.global-title{
                    font-size: 6.4vw;
                }
            }
            &.EN{
                p.global-title{
                    font-size: 4.8vw;
                }
                div.text-content{
                    p.content{
                        font-size: 3.2vw;
                    }
                }
            }
        }
    }
    .cert{
        background: #FCFCFC;
        .img-panel{
            background: #F1F1F1;
            display: inline-block;
            position: relative;
            img{
                position: absolute;
                margin: auto;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
            }
        }
        p.cert-title{
            text-align: center;
        }
        @media screen and (min-device-width: 1200px) {
            height: 88.8rem;
            .cert-title{
                margin: 12rem auto 8rem;
                font-size: 3.6rem;
            }
            .cert-list{
                width: 109.5rem;
                flex-wrap: wrap;
                margin: 0 auto;
                display: flex;
            }
            .img-panel{
                margin: 0 3.5rem 3.5rem 0;
                border-radius: 1.2rem;
                height: 24.5rem;
                width: 18.4rem;
                img{
                    width: 15.2rem;
                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            height: 164.2667vw;
            &.CN{
                p.cert-title{
                    margin: 13.3333vw 0 8vw 0;
                    font-size: 6.4vw;
                }
            }
            &.EN{
                p.cert-title{
                    margin: 8.5333vw auto;
                    height: 13.8666vw;
                    width: 60.5333vw;
                    font-size: 4.8vw;
                }
            }
            .el-carousel__item{
                height: 120.5333vw;
            }
            .el-carousel__indicators--outside{
                transform: translateX(-50%);
                left: 50%;
            }
            .el-carousel__indicators--outside .el-carousel__indicator.is-active button{
                background: #0069AD;
            }
            .el-carousel__indicators--outside button{
                background: rgba(0, 0, 0, .2);
            }
            div.img-panel{
                margin: 0 0 4.2677vw 4.2677vw;
                border-radius: 2vw;
                height: 58.1333vw;
                width: 43.7333vw;
                img{
                    width: 35.4133vw;
                }
            }
        }
    }
    .circle {
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: column;
        align-items: center;
        position: relative;
        display: flex;
        width: 100%;
        .pie-wrapper{
            position: relative;
            img.title-img {
                transform: translate(-50%, -50%);
                position: absolute;
                left: 50%;
                top: 50%;
            }
        }
        .title-wrapper{
            flex-direction: column;
            align-items: center;
            position: absolute;
            text-align: center;
            color: #fff;
            display: flex;
            p.title{
                font-weight: 500;
            }
        }
        img {
            position: absolute;
        }
        img.cover {
            transition: all 0.3s ease;
            opacity: 0;
            z-index: 2;
            &:hover {
                opacity: 1;
            }
        }
        @media screen and (min-device-width: 1200px) {
            height: 99rem;
            div.circle-title{
                margin-top: 10.2rem;
                span{
                    line-height: 5.2rem;
                    font-size: 3.6rem;
                }
            }
            div.circle-content{
                margin-top: 1.2rem;
                span{
                    line-height: 3rem;
                    font-size: 1.8rem;
                }
            }
            .pie-wrapper {
                margin-top: 2.8rem;
                height: 72rem;
                width: 72rem;
                img.normal-1, img.cover-1{
                    width: 28.3rem;
                    top: 0;
                    left: 35.8rem;
                }
                img.normal-2, img.cover-2{
                    width: 24.2rem;
                    top: 13.7rem;
                    left: 47.8rem;
                }
                img.normal-3, img.cover-3{
                    width: 28.6rem;
                    top: 39.7rem;
                    left: 42.4rem;
                }
                img.normal-4, img.cover-4{
                    width: 31.2rem;
                    top: 49.9rem;
                    left: 20.2rem;
                }
                img.normal-5, img.cover-5{
                    width: 28.4rem;
                    top: 39.7rem;
                    left: 0.6rem;
                }
                img.normal-6, img.cover-6{
                    width: 24.1rem;
                    top: 13.7rem;
                    left: -0.4rem;
                }
                img.normal-7, img.cover-7{
                    width: 28rem;
                    top: 0;
                    left: 7.6rem;
                }
            }
            .title-wrapper{
                &.CN{
                    top: 47.6rem;
                    p{
                        width: 13.6rem;
                    }
                    p.title{
                        margin-bottom: .2rem;
                        font-size: 3.2rem;
                    }
                    p.content{
                        font-size: 2.4rem;
                    }
                }
                &.EN{
                    top: 48.6rem;
                    p{
                        width: 20.8rem;
                    }
                    p.title{
                        margin-bottom: .6rem;
                        font-size: 2.2rem;
                    }
                    p.content{
                        font-size: 2rem;
                    }
                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            height: 148.2667vw;

            div.circle-title{
                margin-top: 13.3333vw;
                span{
                    line-height: 9.6vw;
                    font-size: 6.2vw;
                }
            }
            div.circle-content{
                margin: 1.6vw 0 12.8vw 0;
                text-align: center;
                width: 65.6vw;
                span{
                    font-size: 3.2vw;
                }
            }
            &.EN{
                div.circle-title{
                    margin-top: 10.6667vw;
                    width: 74.9333vw;
                    text-align: left;
                    span{
                        line-height: 6.8vw;
                        font-size: 4.8vw;
                        font-weight: 400;
                    }
                }
                div.circle-content{
                    margin: 2.9333vw 0 4.5333vw 0;
                    width: 82.4vw;
                }
            }
            .pie-wrapper{
                height: 83.2vw;
                width: 100%;

                img.normal-1{
                    width: 32.616vw;
                    top: 0;
                    left: 49.834vw;
                }
                img.cover-1{
                    width: 37.3333vw;
                    top: -5.8vw;
                    left: 49.834vw;
                    z-index: 9;
                }
                img.normal-2{
                    width: 27.9467vw;
                    top: 15.6506vw;
                    left: 63.52vw;
                }
                img.cover-2{
                    width: 33.808vw;
                    top: 12vw;
                    left: 63.5vw;
                    z-index: 8;
                }
                img.normal-3{
                    width: 33.008vw;
                    top: 45.4133vw;
                    left: 57.408vw;
                }
                img.cover-3{
                    width: 38.848vw;
                    top: 45.4133vw;
                    left: 57.1vw;
                    z-index: 7;
                }
                img.normal-4{
                    width: 36.024vw;
                    left: 32.0026vw;
                    top: 57.0666vw;
                }
                img.cover-4{
                    width: 41.2373vw;
                    left: 29.4vw;
                    top: 56.9vw;
                    z-index: 6;
                }
                img.normal-5{
                    width: 32.8506vw;
                    top: 45.3866vw;
                    left: 9.5706vw;
                }
                img.cover-5{
                    width: 38.712vw;
                    top: 45.3vw;
                    left: 3.8vw;
                    z-index: 5;
                }
                img.normal-6{
                    width: 27.776vw;
                    top: 15.6133vw;
                    left: 8.5vw;
                }
                img.cover-6{
                    width: 33.8133vw;
                    top: 11.7vw;
                    left: 2.44vw;
                    z-index: 4;
                }
                img.normal-7{
                    width: 32.2586vw;
                    top: -0.01vw;
                    left: 17.5vw;
                }
                img.cover-7{
                    width: 36.9253vw;
                    top: -6vw;
                    left: 13vw;
                    z-index: 9;
                }
            }
            .title-wrapper{
                &.CN{
                    top: 74.4vw;
                    p{
                        width: 15.4vw;
                    }
                    p.title{
                        margin-bottom: 1.2vw;
                        font-size: 3.7333vw;
                    }
                    p.content{
                        font-size: 2.6667vw;
                        font-weight: 350;
                    }
                }
                &.EN{
                    top: 73.8667vw;
                    p.title{
                        margin-bottom: 1vw;
                        line-height: 3.2vw;
                        font-size: 3.2vw;
                        width: 21.6vw;
                    }
                    p.content{
                        line-height: 3.6667vw;
                        font-size: 2.6667vw;
                        width: 30.7333vw;
                    }
                }
            }
        }
    }
    .future{
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: column;
        display: flex;
        width: 100%;
        p.title{
            text-align: center;
            color: #fff;
        }
        @media screen and (min-device-width: 1200px) {
            height: 90.8rem;
            p.title{
                margin: 8.8rem 0 6.4rem 0;
                font-size: 3.6rem;
            }
            div.future-wrapper{
                justify-content: space-between;
                margin: 0 auto;
                width: 106rem;
                display: flex;
            }
        }
        @media screen and (max-device-width: 1199px) {
            height: 120.8vw;
            p.title{
                margin: 13.3333vw 0 5.3333vw 0;
                font-size: 6.4vw;
            }
            &.EN{
                height: 130.6667vw;
            }
        }
    }
    .sector {
        transform-origin: 100px 100px;
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 100px;
        border-radius: 100px 100px 0 0;
        background: red;
        border: 1px solid #fff;
    }
    .card{
        flex-direction: column;
        background: #fff;
        align-items: center;
        display: flex;
        @media screen and (min-device-width: 1200px) {
            border-radius: 1.2rem;
            height: 54.5rem;
            width: 34.2rem;
            .hint-bar{
                height: 0.3rem;
                width: 3.6rem;
            }
            .title-1{
                margin: 7rem 0 1.8rem 0;
                font-size: 2.8rem;
            }
            .title-2{
                margin: 3.2rem 0 1.8rem 0;
                color: #0069AD;
                font-weight: 500;
                font-size: 2.8rem;
            }
            .content{
                text-align: justify;
                font-size: 2.4rem;
                margin: 0 3.6rem;
                font-weight: 400;
                line-break: anywhere;
            }
            &.EN{
                height: 64rem;
                .title-2{
                    margin: 1.8rem 0 1.2rem 0;
                    text-align: center;
                    font-size: 2.4rem;
                    width: 29rem;
                    min-height: 7.2rem;
                }
                .content{
                    text-align: left;
                    font-size: 2rem;
                    width: 28rem;
                    line-break: normal;
                }
            }
        }
        @media screen and (max-device-width: 1199px) {
            border-radius: 4vw;
            margin-left: 3.2vw;
            height: 79.4667vw;
            width: 85.0667vw;
            .hint-bar{
                height: 0.8vw;
                width: 9.6vw;
            }
            .title-1{
                margin: 9.6vw 0 4.8vw 0;
                font-size: 5.3333vw;
            }
            .title-2{
                margin: 7.4667vw 0 8vw 0;
                color: #0069AD;
                font-weight: 500;
                font-size: 4.8vw;
            }
            .content{
                letter-spacing: .42vw;
                text-align: justify;
                font-size: 4.2667vw;
                margin: 0 6.9333vw;
                font-weight: 300;
            }
            &.EN{
                height: 83.2vw;
                .title-2{
                    margin: 3.7333vw 0 0 0;
                    max-width: 67.4667vw;
                    text-align: center;
                    height: 16vw;
                }
                .content{
                    letter-spacing: 0;
                    font-size: 3.2vw;
                    text-align: left;
                }
            }
        }
    }
    .text-content{
        @media screen and (max-device-width: 1199px) {
            margin-top: 8vw;
            .content{
                font-weight: 300;
            }
        }
    }
}
</style>