<template>
    <div class="text-content" :class="[direction]">
        <p class="title">{{ title }}</p>
        <p class="content" v-if="direction === 'row'">{{ content ? ':' + content : '' }}</p>
        <p class="content" v-else>{{ content }}</p>
    </div>
</template>

<script>
export default {
    name: 'text-content',
    props: ['title', 'content', 'direction']
}
</script>

<style lang="less">
div.text-content{
    .title{
        text-align: center;
        color: #0069AD;
        font-weight: 500;
    }
    .content{
        text-align: justify;
        color: #000;
    }
    @media screen and (min-device-width: 1200px) {
        .title{
            font-size: 2.8rem;
        }
        .content{
            margin-top: 1.8rem;
            font-size: 2.4rem;
        }
        &.column{
            width: 25rem;
        }
    }
    @media screen and (max-device-width: 1199px) {
        // width: 91.47vw;
        margin: 0 auto;
        .title{
            font-size: 5.3333vw;
        }
        .content{
            font-size: 4.26667vw;
            margin-top: 1.6vw;
        }
        &.column{
            width: 80.8vw;
        }
    }
}
</style>